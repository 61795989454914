import React from 'react';
import ChartAppUsers from "./ChartAppUsers";
import {Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";

const AppUsers = () => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick
            title={<Typography variant={"h3"}>Notice Access Platforms</Typography>}
            subheader={
                <Typography variant={"h6"} color={"text.secondary"} mb={0}>
                    Breakdown of User Platforms for Opening Notices
                </Typography>
            }
            wrapperSx={{pt: 0}}
        >
            <ChartAppUsers/>
        </JumboCardQuick>
    );
};

export default AppUsers;