import React, { useEffect, useState } from 'react';
import ChartOrders from "./ChartOrders";
import { Typography, Tooltip, Skeleton } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import useAPI from 'app/hooks/useApi';

const UserOrders = () => {
    const [loading, setLoading] = useState(true);
    const [casesCount, setCasesCount] = useState(0);
    const [fillings, setFillings] = useState([]);
    const { POST } = useAPI();

    const formatNumber = (num) => {
        if (num >= 100000) {
            const lakhs = num / 100000;
            return `${lakhs.toFixed(2)} L`;
        } else if (num >= 1000) {
            return `${(num / 1000).toFixed(0)} K`;
        } else {
            return num.toString();
        }
    };

    const fetchCasesAndFillings = async () => {
        try {
            setLoading(true);
            const response = await POST('/stats/case', { type: "filling" });
            if (response) {
                setCasesCount(response.casesCount);
                setFillings(response.fillings);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCasesAndFillings();
    }, []);

    return (
        <JumboCardQuick
            noWrapper
            title={loading ? <Skeleton width={160} height={24} animation="wave" /> : <Typography variant={"h5"} mb={.5}>Cases</Typography>}
            subheader={loading ? <Skeleton width={100} height={24} animation="wave" /> : (
                <Tooltip title={`Exact value: ${casesCount}`} placement="top">
                    <Typography variant={"h6"} color={"text.secondary"} mb={0}>
                        {formatNumber(casesCount)}
                    </Typography>
                </Tooltip>
            )}
            headerSx={{ pb: 0 }}
        >
            <Div sx={{ mt: -2.5 }}>
                {loading ? <Skeleton variant="rectangular" width="100%" height={65} animation="wave" /> : <ChartOrders chartData={fillings} />}
            </Div>
        </JumboCardQuick>
    );
};

export default UserOrders;
