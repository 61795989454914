import React, { useEffect, useState } from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import useAPI from 'app/hooks/useApi';

const Ideas1 = () => {
    const [districtCount, setDistrictCount] = useState(0);
    const { POST } = useAPI();

    const fetchDistrictsCount = async () => {
        try {
            const response = await POST('/stats/district', { type: "count" });
            if (response) {
                setDistrictCount(response.count)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchDistrictsCount();
    }, []);

    return (
        <Card sx={{height: 115, backgroundImage: 'linear-gradient(135deg, #FBC79A, #D73E68)'}}>
            <CardActions disableSpacing sx={{p: 0, alignItems: 'stretch', height: '100%'}}>
                <Div
                    sx={{
                        display: 'flex',
                        width: 126,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        backgroundColor: 'common.white',
                        borderRadius: '50%',
                        outline: 'solid 10px rgba(255, 255, 255, 0.2)',
                        margin: '0 10px 0 -60px'
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 56,
                            height: 56,
                            mr: '6px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <AssuredWorkloadIcon sx={{fontSize: 26}}/>
                    </Div>
                </Div>
                <CardContent sx={{p: 2.5, flex: 1, alignSelf: 'center'}}>
                    <Typography variant={"h4"} color={"common.white"}>{districtCount}</Typography>
                    <Typography variant={"h5"} color={"common.white"} mb={0}>Districts</Typography>
                </CardContent>
            </CardActions>
        </Card>
    );
};

export default Ideas1;
