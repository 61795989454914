import {ASSET_AVATARS} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

export const authUser = {
    email: "harmaynicroft@example.com",
    name: "Harmayni Croft",
    profile_pic: 'https://cdn-icons-png.flaticon.com/512/149/149071.png',
    handle: "harmaynicroft@example.com",
    job_title: "Creative Head"
};
