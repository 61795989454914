import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import List from "@mui/material/List";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import styled from "@emotion/styled";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useAPI from 'app/hooks/useApi';

const ListItemInline = styled(ListItem)(({ theme }) => ({
    width: 'calc(100% / 3 - 10px)',
    display: 'inline-flex',
    padding: theme.spacing(1), 
    margin: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box'
}));

const ChartAppUsers = () => {
    const [platformData, setPlatformData] = useState([]);
    const { POST } = useAPI();

    const generateColorPalette = (count) => {
        return Array.from({ length: count }, (_, i) => `hsl(${(i * 360 / count) % 360}, 70%, 60%)`);
    };

    const fetchPlatformStats = async () => {
        try {
            const response = await POST('/stats/notice', { type: "devices" });
            if (response) {
                const colors = generateColorPalette(response.length);
                const formattedData = response.map((item, index) => ({
                    name: item._id,
                    value: item.count,
                    color: colors[index]
                }));
                setPlatformData(formattedData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPlatformStats();
    }, []);

    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={platformData}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={50}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {
                            platformData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 0,
                    mt: 1
                }}
            >
                {platformData.map((entry, index) => (
                    <ListItemInline key={`list-item-${index}`}>
                        <ListItemIcon sx={{ minWidth: 16 }}>
                            <FiberManualRecordIcon fontSize={"small"} sx={{ color: entry.color }} />
                        </ListItemIcon>
                        <ListItemText primary={entry.name} />
                    </ListItemInline>
                ))}
            </List>
        </React.Fragment>
    );
};

export default ChartAppUsers;
