import React from 'react';
import { Grid } from "@mui/material";
import SalesOverview from "../../../shared/metrics/SalesOverview";
import Documents1 from "./Documents1";
import Ideas1 from "./Ideas1";
import UserOrders from "./UserOrders";
import UserSummary from "../../../shared/widgets/UserSummary";
import LastMonthSales from "../../../shared/metrics/LastMonthSales";
import OnlineSignupsFilled from "../../../shared/metrics/OnlineSignupsFilled";
import NewVisitorsThisMonth from "../../../shared/metrics/NewVisitorsThisMonth";
import TotalRevenueThisYear from "../../../shared/metrics/TotalRevenueThisYear";
import SalesReport1 from "../../../shared/metrics/SalesReport1";
import AppUsers from './metrics/AppUsers';

const MiscDashboard = () => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={7}>
                <SalesOverview />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={6} lg={12}>
                        <Documents1 />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <Ideas1 />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} sm={6} lg={12}>
                        <UserOrders />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                        <UserSummary />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <LastMonthSales />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <OnlineSignupsFilled />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <NewVisitorsThisMonth />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <TotalRevenueThisYear />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AppUsers />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <SalesReport1 />
            </Grid>
        </Grid>
    );
};

export default MiscDashboard;
